if (typeof ibbAds === 'undefined') {
    var ibbAds = {};
}

(function (register) {
    function BbnautId () {

        this.requestData = function(callback) {
            var s = document.createElement('SCRIPT');
            s.src = buildUrl();
            s.async = true;
            s.onload = function() {
                handleResponse(callback);
            };
            document.getElementsByTagName('head')[0].appendChild(s);
        };

        function buildUrl() {
            return 'https://bbcdn-bbnaut.ibillboard.com/library/bbnaut-core-latest.min.js';
        }

        function handleResponse(callback) {
            var ibbId;
            var bbnautLib = window.bbnautLib;
            if( bbnautLib && typeof bbnautLib.getId == 'function') {
                ibbId = bbnautLib.getId();
            }
            callback(ibbId);
        }

    }

    BbnautId.id = 'BbnautIdDataProvider';

    if (!register.dataProviders) { register.dataProviders = {};}
    register.dataProviders[BbnautId.id] = BbnautId;

})(ibbAds);
